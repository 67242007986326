<template>
  <div class="c-tabbar">
    <van-tabbar v-model="active" active-color="#38A6F3" z-index="9999">
      <van-tabbar-item name="home" icon="wap-home-o" to="/">
        <span>首页</span>
      </van-tabbar-item>
      <van-tabbar-item name="project" icon="orders-o" to="/project">
        <span>方案</span>
      </van-tabbar-item>
      <van-tabbar-item name="customer" icon="orders-o" to="/customer">
        <span>客户</span>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: "c-tabbar",
  data() {
    return {
      active: "home",
    };
  },
  created() {
    this.active = this.$route.name;
  },
  activated() {
    this.active = this.$route.name;
  },
};
</script>

<style lang="stylus" scoped>
#c-tabbar {
  z-index: 999;
}
</style>
