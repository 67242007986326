<template>
  <div id="home">
    <div class="current-data">
      <div class="header">
        <span class="l" @click="handleCopyToken()">今日数据</span>
        <span class="r" @click="handleShowQRCode">邀请客户</span>
      </div>
      <div class="body current-body">
        <van-row gutter="10">
          <van-col span="12">
            <div class="card">
              <div class="p1">{{ currentData.total_customer_count }}</div>
              <div class="p2">总客户数</div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="card">
              <div class="p1">{{ currentData.new_customer_count }}</div>
              <div class="p2">今日新开</div>
            </div>
          </van-col>
        </van-row>
        <van-row style="margin-top: 10px" gutter="10">
          <van-col span="12">
            <div class="card">
              <div class="p1">{{ currentData.inactive_customer_count }}</div>
              <div class="p2">维护客户</div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="card">
              <div class="p1">{{ currentData.project_succeed_money }}</div>
              <div class="p2">今日销量</div>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
    <div class="history-data pb50">
      <div class="header">
        <span class="l">历史数据</span>
        <span>{{ month }}</span>
        <span class="r" @click="handleShowMonthPicker">选择月份</span>
      </div>
      <div class="body">
        <table frame="void" rules="none">
          <thead>
            <tr>
              <td>日期</td>
              <td>总客户数</td>
              <td>新开客户</td>
              <td>销售金额</td>
            </tr>
          </thead>
          <tbody v-if="historyData.length > 0">
            <tr v-for="(o, i) in historyData" :key="i">
              <td>
                <span v-if="o.create_date">{{
                  o.create_date | formatDate
                }}</span>
                <span v-else>合计</span>
              </td>
              <td>{{ o.total_customer_count }}</td>
              <td>{{ o.new_customer_count }}</td>
              <td>{{ o.project_succeed_money }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <van-empty
        v-if="historyData.length === 0"
        description="暂无记录"
      ></van-empty>
    </div>
    <van-popup v-model="picker.visible" :closeable="false" position="bottom">
      <van-datetime-picker
        v-model="picker.currentDate"
        @confirm="handleConfirm"
        type="year-month"
        title="选择年月"
        :formatter="formatterMonthPicker"
        :columns-order="['year', 'month']"
        :min-date="picker.minDate"
        :max-date="picker.maxDate"
      />
    </van-popup>
    <van-dialog
      v-model="show"
      @close="handleCloseQRCode"
      :closeOnClickOverlay="true"
      confirmButtonText="关闭"
    >
      <div class="qr-body">
        <vue-qr
          v-if="share_url"
          :text="share_url"
          :margin="0"
          colorDark="#000000"
          colorLight="#fff"
          :logoScale="0.2"
          :size="80"
          style="width: 100%"
        ></vue-qr>
        <div class="invite-code">
          <div v-if="invite_code">邀请码：{{ invite_code }}</div>
          <div v-else>暂无邀请码</div>
        </div>
      </div>
    </van-dialog>
    <c-tabbar></c-tabbar>
  </div>
</template>

<script>
import VueQr from "vue-qr";

import dayjs from "dayjs";
import { mapState } from "vuex";
import CTabbar from "@/components/CTabbar.vue";

import account from "@/api/account";

export default {
  name: "home",
  data() {
    return {
      picker: {
        visible: false,
        currentDate: new Date(),
        minDate: new Date(2021, 0, 1),
        maxDate: new Date(2100, 0, 1),
      },
      month: "",
      // currentData: {},
      // historyData: [],
      show: false,
      share_url: "",
      invite_code: "",
    };
  },
  computed: {
    ...mapState({
      currentData: (state) => state.currentData,
      historyData: (state) => state.historyData,
    }),
  },
  components: {
    VueQr,
    CTabbar,
  },
  methods: {
    handleCopyToken() {
      let str = localStorage.getItem("lta.token");
      this.copyEvent(str);
    },
    formatterMonthPicker(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    handleConfirm(val) {
      this.month = dayjs(val).format("YYYY-MM");
      this.picker.visible = false;

      this.$store.dispatch("getHistoryData", {
        month: this.month,
      });
    },
    handleListHistoryData() {
      let params = {
        month: this.month,
      };
      account.listHistoryData(params).then((res) => {
        this.historyData = res.results;
      });
    },
    handleShowMonthPicker() {
      this.picker.visible = true;
      if (this.month) {
        this.picker.currentDate = new Date(this.month);
      }
    },
    handleShowQRCode() {
      account.getShareUrl().then((res) => {
        this.show = true;
        this.share_url = res.results.share_url;
        this.invite_code = res.results.invite_code;
      });
    },
    handleCloseQRCode() {
      this.show = false;
    },
  },
};
</script>

<style lang="stylus">
#home {
  height: 100%;
  color: #333;
  background: #f7f7f7;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    padding: 0 10px;

    .l {
      label {
        font-size: 12px;
        color: #777;
      }
    }

    .r {
      color: #38a6f3;
    }
  }

  .body {
    background: #fff;

    .card {
      text-align: center;
      background: #f7f7f7;
      color: #222;

      .p1 {
        font-size: 14px;
        padding: 10px 0 5px 0;
      }

      .p2 {
        font-size: 12px;
        padding: 5px 0 10px 0;
      }
    }

    table {
      width: 100%;
      font-size: 12px;
      color: #444;
      text-align: center;

      thead {
        background: #fff;

        td {
          height: 34px;
        }
      }

      tbody {
        td {
          height: 40px;
        }

        tr:nth-child(odd) {
          background: #f7f7f7;
        }
      }
    }
  }

  .current-body {
    padding: 15px;
  }

  .qr-body {
    padding: 20px;

    .invite-code {
      margin-top: 20px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
</style>
